exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-terms-and-conditions-js": () => import("./../../../src/pages/app-terms-and-conditions.js" /* webpackChunkName: "component---src-pages-app-terms-and-conditions-js" */),
  "component---src-pages-app-tutorial-mac-1-js": () => import("./../../../src/pages/app-tutorial-mac-1.js" /* webpackChunkName: "component---src-pages-app-tutorial-mac-1-js" */),
  "component---src-pages-app-tutorial-mac-2-js": () => import("./../../../src/pages/app-tutorial-mac-2.js" /* webpackChunkName: "component---src-pages-app-tutorial-mac-2-js" */),
  "component---src-pages-app-tutorial-pc-1-js": () => import("./../../../src/pages/app-tutorial-pc-1.js" /* webpackChunkName: "component---src-pages-app-tutorial-pc-1-js" */),
  "component---src-pages-app-tutorial-pc-2-js": () => import("./../../../src/pages/app-tutorial-pc-2.js" /* webpackChunkName: "component---src-pages-app-tutorial-pc-2-js" */),
  "component---src-pages-compatibility-en-us-js": () => import("./../../../src/pages/compatibility.en-US.js" /* webpackChunkName: "component---src-pages-compatibility-en-us-js" */),
  "component---src-pages-compatibility-ja-jp-js": () => import("./../../../src/pages/compatibility.ja-JP.js" /* webpackChunkName: "component---src-pages-compatibility-ja-jp-js" */),
  "component---src-pages-compatibility-zh-hk-js": () => import("./../../../src/pages/compatibility.zh-HK.js" /* webpackChunkName: "component---src-pages-compatibility-zh-hk-js" */),
  "component---src-pages-downloads-en-us-js": () => import("./../../../src/pages/downloads.en-US.js" /* webpackChunkName: "component---src-pages-downloads-en-us-js" */),
  "component---src-pages-downloads-ja-jp-js": () => import("./../../../src/pages/downloads.ja-JP.js" /* webpackChunkName: "component---src-pages-downloads-ja-jp-js" */),
  "component---src-pages-downloads-zh-hk-js": () => import("./../../../src/pages/downloads.zh-HK.js" /* webpackChunkName: "component---src-pages-downloads-zh-hk-js" */),
  "component---src-pages-how-to-reset-firmware-js": () => import("./../../../src/pages/how-to-reset-firmware.js" /* webpackChunkName: "component---src-pages-how-to-reset-firmware-js" */),
  "component---src-pages-index-en-us-js": () => import("./../../../src/pages/index.en-US.js" /* webpackChunkName: "component---src-pages-index-en-us-js" */),
  "component---src-pages-index-ja-jp-js": () => import("./../../../src/pages/index.ja-JP.js" /* webpackChunkName: "component---src-pages-index-ja-jp-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-zh-hk-js": () => import("./../../../src/pages/index.zh-HK.js" /* webpackChunkName: "component---src-pages-index-zh-hk-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-templates-project-item-jsx": () => import("./../../../src/templates/project-item.jsx" /* webpackChunkName: "component---src-templates-project-item-jsx" */)
}

